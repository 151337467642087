import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { BsSearch } from "react-icons/bs";
import { getBaoCaoTonKhoOn } from '../../state/BaoCaoTonKho/BaoCaoTonKho';
import MsBoxModal from '../Modal/MsBoxModal'
import { IMAGES_URL_NB } from '../../config/apiConfig';

export default function BaoCaoBanHangTonKhoHeThongOn() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [loading, setLoading] = useState(true); 
  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [_maSanPham, _setMaSanPham] = useState('');
  const [_baoCaoTonKho, _setBaoCaoTonKho] = useState([]);
  const [message, setMessage] = useState('')
  const dispatch = useDispatch();
  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handSearch = () => {

    setLoading(true);
    dispatch(getBaoCaoTonKhoOn(_maSanPham)).then((Result) => {
      _setBaoCaoTonKho(Result?.store)
    });
    setLoading(false);

  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handSearch();
    }
  };
  const handleCloseModal = () => {
    setOpenAuthModal(false);
  };
  const handleMsBox = () => {
    setOpenAuthModal(true);
  }
  
  return (
    
    <div className=' pt-3 pl-2' >
 
      <div className="border rounded-md lg:w-1/5  max-w-xs flex ">
        <input
          type="text"
          name="maSanPham"
          id="maSanPham"
          autoComplete="maSanPham"
          onChange={(event) => _setMaSanPham(event.target.value)}
          onKeyDown={handleKeyDown}
          onInput={toInputUppercase}
          className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full "
        />
        <BsSearch onClick={handSearch} className=' cursor-pointer text-3xl pl-1 pr-1 pt-2 ' />
      </div>
      <div class="pt-2 flex flex-col h-screen w-screen">
      {loading ? (
                <p>Loading...</p> // Hiển thị "Loading..." khi đang chờ dữ liệu
            ) : (
        <div className="overflow-x-auto">
          <table className="min-w-full border-collapse border"> 
            <thead class="">
              <tr >
                <th className="sticky top-0 left-0 z-20 bg-pink-400 text-white p-1 text-left border-b border-pink-200 whitespace-nowrap w-auto">Mã sản phẩm</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">ĐG</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">ON</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">HBT</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">NT</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">CM</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">NK</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">LVS</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">LDH</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">HG</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">QT</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">TN</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">PVC</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">TD</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">BD</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">BH</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">DN</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">NTR</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">BMT</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">PT</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">H</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">V</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">BT</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">CB</th>
                <th className="sticky top-0 bg-teal-300 text-teal-900 p-1 min-w-[40px] text-center border-b border-teal-200">CG</th>
                
              </tr>
            </thead>
            <tbody >
              {_baoCaoTonKho?.map((row, index) => (
                <tr key={index} className="even:bg-teal-50 hover:bg-teal-100">
                  <td  className="sticky left-0 z-10 bg-pink-200 p-1 font-semibold border-r border-pink-200  whitespace-nowrap w-auto">
                    <div className='flex'>
                    <img src={IMAGES_URL_NB + row.MaSanPham.trim().substring(0, row.MaSanPham.trim().length -3) + '.jpg'} className=' h-6 w-4 cursor-pointer ' onClick={() => (setMessage(<div  className={windowSize[0]<windowSize[1]?'w-[360px]':'w-[250px]'}> <img src={IMAGES_URL_NB + row.MaSanPham.trim().substring(0, row.MaSanPham.trim().length -3) + '.jpg'} /> </div>), handleMsBox())} />
                    <span className='pl-1'>  {row.MaSanPham} </span> </div>
                    </td>
                  <td className="p-1 text-center border-r border-teal-100">{row.DonGiaXuat}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.ON < 0 ? 0 : row.ON > 5 ? 5 : row.ON}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.HBT < 0 ? 0 : row.HBT > 5 ? 5 : row.HBT}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.NT < 0 ? 0 : row.NT > 5 ? 5 : row.NT}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.CMT8 < 0 ? 0 : row.CMT8 > 5 ? 5 : row.CMT8}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.NKKN < 0 ? 0 : row.NKKN > 5 ? 5 : row.NKKN}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.LVS < 0 ? 0 : row.LVS > 5 ? 5 : row.LVS}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.LDH < 0 ? 0 : row.LDH > 5 ? 5 : row.LDH}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.HG < 0 ? 0 : row.HG > 5 ? 5 : row.HG}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.QT < 0 ? 0 : row.QT > 5 ? 5 : row.QT}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.TN < 0 ? 0 : row.TN > 5 ? 5 : row.TN}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.PVC < 0 ? 0 : row.PVC > 5 ? 5 : row.PVC}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.TD < 0 ? 0 : row.TD > 5 ? 5 : row.TD}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.BD < 0 ? 0 : row.BD > 5 ? 5 : row.BD}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.BH < 0 ? 0 : row.BH > 5 ? 5 : row.BH}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.DN < 0 ? 0 : row.DN > 5 ? 5 : row.DN}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.NTR < 0 ? 0 : row.NTR > 5 ? 5 : row.NTR}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.BMT < 0 ? 0 : row.BMT > 5 ? 5 : row.BMT}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.PT < 0 ? 0 : row.PT > 5 ? 5 : row.PT}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.H < 0 ? 0 : row.H > 5 ? 5 : row.H}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.V < 0 ? 0 : row.V > 5 ? 5 : row.V}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.BT < 0 ? 0 : row.BT > 5 ? 5 : row.BT}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.CB < 0 ? 0 : row.CB > 5 ? 5 : row.CB}</td>
                  <td className="p-1 text-center border-r border-teal-100">{row.CG < 0 ? 0 : row.CG > 5 ? 5 : row.CG}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

)}

      </div>
      
      <MsBoxModal handleClose={handleCloseModal} open={openAuthModal} Message={message} />
    </div>
  )
}
