import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { BsSearch } from "react-icons/bs";
import { getBaoCaoBanHangTonKho } from '../../state/BaoCaoTonKho/BaoCaoTonKho';
import MsBoxModal from '../Modal/MsBoxModal'
import { IMAGES_URL_NB } from '../../config/apiConfig';

export default function BaoCaoBanHangTonKhoHeThong() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [_tuNgay, setTuNgay] = React.useState(new Date().toISOString().substring(0, 10));
  const [_denNgay, setDenNgay] = React.useState(new Date().toISOString().substring(0, 10));
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [_maSanPham, _setMaSanPham] = useState('');
  const [_baoCaoTonKho, _setBaoCaoTonKho] = useState([]);
  const [message, setMessage] = useState('')
  const dispatch = useDispatch();
  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handleSearch = () =>{
    dispatch(getBaoCaoBanHangTonKho(_maSanPham)).then((Result) => {
      _setBaoCaoTonKho(Result?.store)
    });
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };
  const handleCloseModal = () => {
    setOpenAuthModal(false);
  };
  const handleMsBox = () => {
    setOpenAuthModal(true);
  }
  const calculateSum = (columnName) => {
    return _baoCaoTonKho.reduce((acc, item) => acc + item[columnName], 0);
  };
  const sumOfSLN = calculateSum('SLN');
  const sumOfSLB = calculateSum('SLB');
  const sumOfTONG = calculateSum('TONG');
  const sumOfTHT = calculateSum('THT');
  return (
    <div className=' pt-3 pl-2' >
      {/* <div>
      <span className='pt-1 pr-2'>Từ ngày:</span>
      <input
      
        type="date" // Use the appropriate input type for datetime
        value={_tuNgay}
        onChange={(e) => (setTuNgay(e.target.value))}
        className=' pl-3'
      />
      </div>
      <div>
      <span className='pt-1 pr-2'>Đến ngày:</span>
      <input
        type="date" // Use the appropriate input type for datetime
        value={_denNgay}
        onChange={(e) => (setDenNgay(e.target.value))}
        className='pb-2'
      />
      </div> */}
      <div className="border rounded-md lg:w-1/5  max-w-xs flex ">
        <input
          type="text"
          name="maSanPham"
          id="maSanPham"
          autoComplete="maSanPham"
          onChange={(event) => _setMaSanPham(event.target.value)}
          onKeyDown={handleKeyDown}
          onInput={toInputUppercase}
          className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full "
        />
        <BsSearch onClick={handleSearch} className=' cursor-pointer text-3xl pl-1 pr-1 pt-2 ' />
      </div>
      <div className='pt-2'>
        <table class=" table-auto ">
          <thead class="">
            <tr class="border border-grey-500">
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">Mã sản phẩm</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">Giá</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">TONG</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">THT</th>
            </tr>
          </thead>
          <tbody >
            {_baoCaoTonKho?.map((row, index) => (
              <tr key={index} class="bg-gray-300 border border-grey-500 ">
                <td class="p-2 md:border md:border-grey-500 text-left  "><div className='flex'>
                  <img src={IMAGES_URL_NB + row.MaSanPham.trim() + '.jpg'} className=' h-6 w-4 cursor-pointer ' onClick={() => (setMessage(<div  className={windowSize[0]<windowSize[1]?'w-[360px]':'w-[250px]'}> <img src={IMAGES_URL_NB + row.MaSanPham.trim() + '.jpg'} /> </div>), handleMsBox())} />
                  <span className='pl-1'>  {row.MaSanPham} </span> </div></td>
                
                <td class="p-2 md:border md:border-grey-500 text-left  ">{row.DonGia}</td>
                <td class="p-2 md:border md:border-grey-500 text-left  ">{row.TONG}</td>
                <td class="p-2 md:border md:border-grey-500 text-left  ">{row.THT}</td>
              </tr>
            ))}
            <tr class="border border-grey-500">
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">TONG SL</th>
              
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  "></th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">{sumOfTONG}</th>
              <th class="bg-gray-600 p-2 text-white font-bold md:border md:border-grey-500 text-left  ">{sumOfTHT}</th>
            </tr>
          </tbody>
        </table>
      </div>
      <MsBoxModal handleClose={handleCloseModal} open={openAuthModal} Message={message} />
    </div>
  )
}
