import React from 'react';
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-br from-pink-100 to-blue-200 text-center">
    <h1 className="text-6xl font-bold text-pink-600">Oops!</h1>
    <p className="text-lg text-blue-600 mt-4">It looks like you're lost...</p>
    
    <div className="mt-8">
      <img
        src="https://i.imgur.com/qIufhof.png" 
        alt="Unauthorized Cute" 
        className="w-64 h-64 mx-auto"
      />
    </div>
    
    <p className="text-xl mt-6 text-gray-700">You don't have permission to access this page.</p>
    
    <Link to="/" className="mt-8 px-6 py-3 bg-pink-500 text-white rounded-full shadow-lg hover:bg-pink-600 transition duration-300">
      Go Back Home
    </Link>
    
    <div className="mt-10 text-sm text-gray-500">
      If you think this is a mistake, please contact support.
    </div>
  </div>
  );
};

export default UnauthorizedPage;
