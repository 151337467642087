import React from 'react'
import {  Navigate, Route, Routes } from 'react-router-dom'
import Home from '../components/Home/Home'
import LoginForm from '../auth/LoginForm'
import BaoCaoTonKhoHeThong from '../components/BaoCaoTonKho/BaoCaoTonKhoHeThong'
import BaoCaoBanHangTonKhoHeThong from '../components/BaoCaoTonKho/BaoCaoBanHangTonKhoHeThong'
import BaoCaoBanHangTonKhoHeThongOn from '../components/BaoCaoTonKho/BaoCaoTonKhoHeThongOn'
import UnauthorizedPage from './UnauthorizedPage'
import ProtectedRoute from './ProtectedRoute'

export default function () {
  return (
    <Routes>
        <Route path='/' element={<Navigate to='/home'/>} />
        {/* <Route path='/home' element={<Home/>} /> */}
        <Route path='/login' element={<LoginForm/>} />
        <Route path='/register' element={<Home/>} />

       
        <Route
          path="/BaoCaoBanHangTonKhoHeThong"
          element={
            <ProtectedRoute requiredRoles={['6cfe2b93-0d55-4c7a-8f87-b8e930e3c886', '837139ef-d926-467d-8e30-3b4d41cf8af0']}>
             <BaoCaoBanHangTonKhoHeThong/>
            </ProtectedRoute>
          }
        />

        
        <Route
          path="/BaoCaoTonKhoHeThong"
          element={
            <ProtectedRoute requiredRoles={['6cfe2b93-0d55-4c7a-8f87-b8e930e3c886', '46d6df42-a086-4c19-b0b4-57323a963b18', '837139ef-d926-467d-8e30-3b4d41cf8af0']}>
             <BaoCaoTonKhoHeThong/>
            </ProtectedRoute>
          }
        />
        <Route
          path="/BaoCaoTonKhoHeThongOn"
          element={
            <ProtectedRoute requiredRoles={['a153fda4-0e72-4a4f-b552-41fc63468dea', '837139ef-d926-467d-8e30-3b4d41cf8af0']}>
             <BaoCaoBanHangTonKhoHeThongOn/>
            </ProtectedRoute>
          }
        />
        
       {/* Trang Unauthorized khi người dùng không có quyền */}
       <Route path="/unauthorized" element={<UnauthorizedPage />} />
    </Routes>
  )
}
