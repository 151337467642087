import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, requiredRoles }) => {
  const userRole = localStorage.getItem('ekey'); // Lấy quyền từ localStorage

  if (!userRole || !requiredRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" />; // Chuyển hướng nếu không có quyền
  }

  return children; // Nếu đúng quyền thì render component con
};

export default ProtectedRoute;
