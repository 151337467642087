import React, { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { BsSearch } from "react-icons/bs";
import { getBaoCaoTonKho } from '../../state/BaoCaoTonKho/BaoCaoTonKho';
import MsBoxModal from '../Modal/MsBoxModal'
import { IMAGES_URL_NB } from '../../config/apiConfig';

export default function BaoCaoBanHangTonKhoHeThong() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [_maSanPham, _setMaSanPham] = useState('');
  const [_baoCaoTonKho, _setBaoCaoTonKho] = useState([]);
  const [message, setMessage] = useState('')
  const dispatch = useDispatch();
  const toInputUppercase = e => {
    e.target.value = ("" + e.target.value).toUpperCase();
  };
  const handSearch = () => {
    dispatch(getBaoCaoTonKho(_maSanPham)).then((Result) => {
      _setBaoCaoTonKho(Result?.store)
    });
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handSearch();
    }
  };
  const handleCloseModal = () => {
    setOpenAuthModal(false);
  };
  const handleMsBox = () => {
    setOpenAuthModal(true);
  }
  
  return (
    
    <div className=' pt-3 pl-2' >
 
      <div className="border rounded-md lg:w-1/5  max-w-xs flex ">
        <input
          type="text"
          name="maSanPham"
          id="maSanPham"
          autoComplete="maSanPham"
          onChange={(event) => _setMaSanPham(event.target.value)}
          onKeyDown={handleKeyDown}
          onInput={toInputUppercase}
          className="border-1 border-gray-500 p-2 rounded-md focus:outline-teal-500 w-full h-full "
        />
        <BsSearch onClick={handSearch} className=' cursor-pointer text-3xl pl-1 pr-1 pt-2 ' />
      </div>
      <div class="pt-2 flex flex-col h-screen w-screen">
        <div className=' flex-grow overflow-auto'>
          <table class=" w-full bg-white border border-gray-300">
            <thead class="">
              <tr class="sticky top-0 bg-gray-600 border border-grey-500">
                <th className='bg-gray-600 border border-grey-500 min-w-[160px]'>Mã sản phẩm</th>
                <th class="border border-grey-500 ">TONG</th>
                <th class="border border-grey-500 ">HBT</th>
                <th class="border border-grey-500 ">ON</th>
                <th class="border border-grey-500 ">NT</th>
                <th class="border border-grey-500 ">CMT8</th>
                <th class="border border-grey-500 ">NKKN</th>
                <th class="border border-grey-500 ">LVS</th>
                <th class="border border-grey-500 ">LDH</th>
                <th class="border border-grey-500 ">HG</th>
                <th class="border border-grey-500 ">QT</th>
                <th class="border border-grey-500 ">TN</th>
                <th class="border border-grey-500 ">PVC</th>
                <th class="border border-grey-500 ">TD</th>
                <th class="border border-grey-500 ">BD</th>
                <th class="border border-grey-500 ">BH</th>
                <th class="border border-grey-500 ">DN</th>
                <th class="border border-grey-500 ">NTR</th>
                <th class="border border-grey-500 ">BMT</th>
                <th class="border border-grey-500 ">PT</th>
                <th class="border border-grey-500 ">HUE</th>
                <th class="border border-grey-500 ">V</th>
                <th class="border border-grey-500 ">BT</th>
                <th class="border border-grey-500 ">CB</th>
                <th class="border border-grey-500 ">CG</th>
              </tr>
            </thead>
            <tbody >
              {_baoCaoTonKho?.map((row, index) => (
                <tr key={index} class="border border-grey-500 ">
                  <td class=" sticky left-0 border border-grey-500 bg-white ">
                    <div className='flex'>
                    <img src={IMAGES_URL_NB + row.MaSanPham.trim() + '.jpg'} className=' h-6 w-4 cursor-pointer ' onClick={() => (setMessage(<div  className={windowSize[0]<windowSize[1]?'w-[360px]':'w-[250px]'}> <img src={IMAGES_URL_NB + row.MaSanPham.trim() + '.jpg'} /> </div>), handleMsBox())} />
                    <span className='pl-1'>  {row.MaSanPham} </span> </div>
                    
                    </td>
                 
                 
                  <td class="border border-grey-500 ">{row.TONG}</td>
                  <td class="border border-grey-500 ">{row.HBT}</td>
                  <td class="border border-grey-500 ">{row.ON}</td>
                  <td class="border border-grey-500 ">{row.NT}</td>
                  <td class="border border-grey-500 ">{row.CMT8}</td>
                  <td class="border border-grey-500 ">{row.NKKN}</td>
                  <td class="border border-grey-500 ">{row.LVS}</td>
                  <td class="border border-grey-500 ">{row.LDH}</td>
                  <td class="border border-grey-500 ">{row.HG}</td>
                  <td class="border border-grey-500 ">{row.QT}</td>
                  <td class="border border-grey-500 ">{row.TN}</td>
                  <td class="border border-grey-500 ">{row.PVC}</td>
                  <td class="border border-grey-500 ">{row.TD}</td>
                  <td class="border border-grey-500 ">{row.BD}</td>
                  <td class="border border-grey-500 ">{row.BH}</td>
                  <td class="border border-grey-500 ">{row.DN}</td>
                  <td class="border border-grey-500 ">{row.NTR}</td>
                  <td class="border border-grey-500 ">{row.BMT}</td>
                  <td class="border border-grey-500 ">{row.PT}</td>
                  <td class="border border-grey-500 ">{row.HUE}</td>
                  <td class="border border-grey-500 ">{row.V}</td>
                  <td class="border border-grey-500 ">{row.BT}</td>
                  <td class="border border-grey-500 ">{row.CB}</td>
                  <td class="border border-grey-500 ">{row.CG}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      
      <MsBoxModal handleClose={handleCloseModal} open={openAuthModal} Message={message} />
    </div>
  )
}
