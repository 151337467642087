import { api } from "../../config/apiConfig";
export const getBaoCaoBanHangTonKho=(MaSanPham)=> async()=>{
    try{
        const {data} = await api.get(`BCBHTKHT/${MaSanPham}`);
        return data;
    }catch(error){
        return error;
    }
}

export const getBaoCaoTonKho=(MaSanPham)=> async()=>{
    try{
        const {data} = await api.get(`BCTKHT/${MaSanPham}`);
        return data;
    }catch(error){
        return error;
    }
}
export const getBaoCaoTonKhoOn=(MaSanPham)=> async()=>{
    try{
        const {data} = await api.get(`BCTKHTON/${MaSanPham}`);
        return data;
    }catch(error){
        return error;
    }
}