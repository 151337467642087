import React from 'react'
import Layout from './components/Layout/Layout'

export default function App() {
  // if (window.location.protocol === 'http:') {
  //   window.location.href = window.location.href.replace('http:', 'https:');
  // }
  
  return (
    
    <Layout/>
  )
}
